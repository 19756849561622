<template>
  <div>
    <b-card>
      <ValidationObserver ref="form">
        <b-row>
          <b-col cols="12" md="6" lg="4">
            <ek-input-text
              :rules="[{ type: 'required', message: 'الاسم مطلوب' }]"
              name="fullName"
              placeholder="أدخل الاسم الكامل"
              label="الاسم الكامل"
              v-model="userDto.name"
            >
            </ek-input-text>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <ek-input-text
              :rules="[{ type: 'required', message: 'اسم المستخدم مطلوب' }]"
              name="username"
              placeholder="أدخل اسم المستخدم"
              label="اسم المستخدم"
              v-model="userDto.userName"
            >
            </ek-input-text>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <ek-input-text
              :rules="[
                { type: 'required', message: 'الاسم مطلوب' },
                { type: 'email', message: 'يرجى ادخال بريد الكتروني صحيح' },
              ]"
              name="email"
              placeholder="ادخل البريد الالكتروني"
              label="البريد الالكتروني"
              v-model="userDto.email"
            >
            </ek-input-text>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <ek-input-text
              :rules="[
                { type: 'min:4', message: 'كلمة المرور يجب ان تكون اطول من 4 محارف' },
              ]"
              name="password"
              placeholder="ادخل كلمة المرور الجديدة"
              label="تغيير المرور"
              v-model="userDto.password"
            >
            </ek-input-text>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <ek-input-text
              :rules="[
                { type: 'required', message: 'رقم الهاتف مطلوب' },
                { type: 'mobile', message: 'رقم الهاتف غير صالح' },
              ]"
              name="phoneNumber"
              placeholder="أدخل الهاتف"
              label="رقم الهاتف"
              v-model="userDto.phoneNumber"
            >
            </ek-input-text>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <ek-date-picker
              :rules="[{ type: 'required', message: 'تاريخ الميلاد مطلوب' }]"
              name="تاريخ الميلاد"
              placeholder=" أدخل تاريخ الميلاد"
              label="تاريخ الميلاد "
              v-model="userDto.birthDate"
            >
            </ek-date-picker>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <ek-input-select
              name="الصلاحيات"
              placeholder=" اختر صلاحية المستخدم"
              label="الصلاحيات"
              v-model="userDto.role"
              valueLabel="value"
              :options="[
                { name: 'مسؤول', value: 1 },
                { name: 'مستخدم', value: 2 },
              ]"
            >
            </ek-input-select>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #footer>
        <div class="w-100 d-flex justify-content-between">
          <div>
            <b-button variant="primary" @click="submit">تعديل</b-button>
            <b-button variant="outline-primary " class="mx-1" to="/admin/accounts"
              >تراجع</b-button
            >
          </div>
          <div>
            <b-button variant="outline-danger" class="mx-1" @click="setBlock">{{
              userDto.isBlocked ? "الغاء الحظر" : "حظر"
            }}</b-button>
            <b-button variant="danger" @click="deleteAccount(id)">حذف</b-button>
          </div>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState({
      userDto: ({ accounts }) => accounts.userDto,
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions([
      "getUserDetails",
      "updateAccount",
      "unBlockAccount",
      "blockAccount",
      "deleteAccount",
    ]),
    submit() {
      this.$refs["form"].validate().then((suc) => {
        if (suc) this.updateAccount(this.userDto);
      });
    },
    setBlock() {
      if (this.userDto.isBlocked) {
        this.unBlockAccount(this.id);
      } else {
        this.blockAccount(this.id);
      }
    },
  },
  created() {
    this.getUserDetails(this.id);
  },
  beforeDestroy() {
    this.$store.commit("Set_User_Dto");
  },
};
</script>

<style></style>
